console.log('I have entered this file.');
console.log('This is crystal clear evidence that this works.');

const calculation = 200 + 223;

console.log('Sanity Check: 200 + 223 = ' + calculation);
console.log('bye.');


/* Preloader */
$(window).on('load', function() { // makes sure the whole site is loaded
  $('#status').fadeOut(); // will first fade out the loading animation
  $('#preloader').delay(350).fadeOut('slow'); // will fade out the white DIV that covers the website.
  $('body').delay(350).css({'overflow':'visible'});
})


/* Menu Scroll */
$(document).ready(function(){
	$(window).scroll(function() { // check if scroll event happened
		if ($(document).scrollTop() > 650) { // check if user scrolled more than 50 from top of the browser window
			$(".navButton").css("color", "#6327D7");
			$(".logoArticle").css("fill", "#6327D7"); // if yes, then change the color of class "navbar-fixed-top" to white (#f8f8f8)
      $(".spanMenu").css("background", "#6327D7");
		} else {
			$(".navButton").css("color", "#fff"); // if not, change it back to transparent
			$(".logoArticle").css("fill", "#fff");
      $(".spanMenu").css("background", "#fff");
		}
	});
});


/* Responsive Menu */
$('#toggle').click(function() {
   $(this).toggleClass('active');
   $('#overlay').toggleClass('open');
   $(".logoNav").toggleClass('fill');
});


/* Animation */
$(document).ready(function(){
	// Init ScrollMagic
  var controller = new ScrollMagic.Controller();
	// Loop through each project element
	$('.project').each(function(){
		// Build a Scene
	  var ourScene = new ScrollMagic.Scene({
	    triggerElement: this.children[0],
			triggerHook: 0.5
	  })
	  .setClassToggle(this, 'fade-in') // Add class to Div Class outer
	  .addTo(controller);
	});
});


/* Video Popup */
new fullpage('#fullpage', {
	licenseKey: '3EF57DDF-74F3476C-B4A1DD35-60F333A3',
	sectionsColor: ['#6327D7', '#6327D7', '#C0C0C0', '#ADD8E6'],
	slideSelector: '.horizontal-scrolling',
	navigation: true
});
